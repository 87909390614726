import { css, SerializedStyles } from "@emotion/react";
import { IconButton } from "@theme-ui/components";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Phone from "./../../assets/phone.inline.svg"
import Plus from "./../../assets/plus.inline.svg"

import ContratAjustable from "./../../assets/contrat-ajustable.inline.svg"
import FacebookIcon from "./../../assets/facebook-icon.inline.svg"
import FlecheAngleDroit from "./../../assets/fleche-angle-droit.inline.svg"
import FlecheBoucle from "./../../assets/fleche-boucle.inline.svg"
import InstaIcon from "./../../assets/insta-icon.inline.svg"
import Loupe from "./../../assets/loupe.inline.svg"
import PouceRecommandation from "./../../assets/pouce-recommandation.inline.svg"
import MutualiteAveyron from "./../../assets/mutualite-aveyron.inline.svg"
import { animated, useSpring } from 'react-spring';
import { Link } from "gatsby";
import { Link as ExternalLink } from 'theme-ui';
interface props {
    iconName: string
    to?: string
    onClick?: (event: Event) => void
    style?: SerializedStyles
    children?: React.ReactNode
    variant?: string
    sx?: {}
    target?: string
}

export const Icon = ({ iconName, to = "", target, onClick, style, children, variant = "", sx = {} }: props) => {
    const defaultStyle = css`
    width:2em;
    height:2em;
    `

    if (onClick) {
        return (
            <Boop rotation={15} sx={{ width: "fit-content", height: "fit-content", ...sx }}>
                <IconButton onClick={onClick} variant={variant} sx={{ cursor: "pointer", width: "fit-content", height: "fit-content", padding: "0" }}>
                    {getIcon(iconName, style || defaultStyle)}
                    {children}
                </IconButton >
            </Boop>
        )
    }
    if (to) {
        return (
            <Boop
                rotation={15}
                sx={{ width: "fit-content", height: "fit-content", ...sx }}
            >
                {!target && (
                    <IconButton
                        as={Link}
                        to={to}
                        variant={variant}
                        sx={{
                            cursor: "pointer",
                            width: "fit-content",
                            height: "fit-content",
                            textAlign: "center",
                            textDecoration: "none",
                        }}
                    >
                        {getIcon(iconName, style || defaultStyle)}
                        {children}
                    </IconButton>
                )}

                {target && (
                    <IconButton
                        as={ExternalLink}
                        href={to}
                        target={target}
                        variant={variant}
                        sx={{
                            cursor: "pointer",
                            width: "fit-content",
                            height: "fit-content",
                            textAlign: "center",
                            textDecoration: "none",
                        }}
                    >
                        {getIcon(iconName, style || defaultStyle)}
                        {children}
                    </IconButton>
                )}
            </Boop>
        );
    }

    return (
        <IconButton sx={{ width: "fit-content", height: "fit-content", ...sx }} variant={variant}>
            {getIcon(iconName, style || defaultStyle)}
            {children}
        </IconButton>
    )
}


const getIcon = (iconName: string, cssIcon: SerializedStyles) => {
    switch (iconName) {
        case "aide":
            return <StaticImage css={cssIcon} layout="constrained" src="./../../images/aide-couleur.png" alt="logo aide" />
        case "enfant":
            return <StaticImage css={cssIcon} layout="constrained" src="./../../images/garde-enfant.png" alt="logo enfant" />
        case "menage":
            return <StaticImage css={cssIcon} layout="constrained" src="./../../images/menage.png" alt="logo menage" />
        case "restauration":
            return <StaticImage css={cssIcon} layout="constrained" src="./../../images/restauration.png" alt="logo restauration" />
        case "phone-mail":
            return <Phone css={cssIcon} alt="logo phone" />
        case "plus":
            return <Plus css={cssIcon} alt="logo add" />
        case "contrat-ajustable":
            return <ContratAjustable alt="logo contrat" css={css`.cls-1{fill:#faeb3d; stroke: transparent}${cssIcon}`} />
        case "facebook-icon":
            return <FacebookIcon css={css`.cls-1{fill:black; stroke: transparent}${cssIcon}`} alt="logo facebook" />
        case "fleche-angle-droit":
            return <FlecheAngleDroit css={cssIcon} alt="logo fleche angle" />
        case "fleche-boucle":
            return <FlecheBoucle css={cssIcon} alt="logo fleche" css={css`path{stroke:#343231};${cssIcon}`} />
        case "insta-icon":
            return <InstaIcon css={css`.cls-1{fill:black; stroke: transparent;}${cssIcon}`} alt="logo instagram" />
        case "loupe":
            return <Loupe alt="logo recommandation" css={css`.cls-1{fill:#faeb3d;stroke: transparent}${cssIcon}`} />
        case "pouce-recommandation":
            return <PouceRecommandation alt="logo add" css={css`.cls-1{fill:#faeb3d;stroke: transparent}${cssIcon}`} />
        case "mutuelle-icon":
            return <MutualiteAveyron alt="logo mutualite aveyron" css={css`.cls-1{fill:black;stroke: transparent}${cssIcon}`} />
        default:
            return <DefaultIcon css={cssIcon} />
    }

}


const DefaultIcon = ({ color = "currentcolor" }) => (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill={color}>
    <circle
        r={11}
        cx={12}
        cy={12}
        fill="none"
        stroke={color}
        strokeWidth={2}
    />
</svg>)




export const Boop = ({ rotation = 0, timing = 150, children, sx = {} }) => {
    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
        display: 'inline-block',
        backfaceVisibility: 'hidden',
        transform: isBooped
            ? `rotate(${rotation}deg)`
            : `rotate(0deg)`,
        ...sx,
        config: {
            tension: 300,
            friction: 10,
        },
    });
    React.useEffect(() => {
        // We only want to act when we're going from
        // not-booped to booped.
        if (!isBooped) {
            return;
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        // Just in case our component happens to
        // unmount while we're booped, cancel
        // the timeout to avoid a memory leak.
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);

    const trigger = () => {
        // Unchanged
        setIsBooped(true);
    };
    return (
        <animated.span onMouseEnter={trigger} style={style}>
            {children}
        </animated.span>
    );
};